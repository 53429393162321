.heading {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
}

.heading button {
  position: absolute;
  left: -6px;
  transform: translateX(-100%);
  background: transparent;
  border: 0;
  padding: 4px;
  cursor: pointer;
  color: #808080;
  opacity: 0;
  transition: 0.2s;
}

.heading:has(h3:hover) button {
  opacity: 1;
}

.heading button:hover {
  opacity: 1;
  color: #dddddd;
}

.heading h3 {
  width: fit-content;
  font-weight: normal;
  font-size: 24px;
  color: #dddddd;
  font-family: "agency", sans-serif;
  cursor: pointer;
}

.heading span {
  position: absolute;
  bottom: -15px;
  font-size: 10px;
  color: #808080;
  font-family: "fetropolis", sans-serif;
}

.heading span a {
  color: #808080;
  text-decoration: none;
  transition: 0.2s;
}

.heading span a:hover {
  color: #437cb2;
}
