header {
  height: 80px;
  width: 100%;
  background-color: #282828;
  text-align: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #808080;
}

.header-left {
  margin-left: 50px;
  width: min-content;
  z-index: 2;
  height: 100%;
  display: flex;
  align-items: center;
}

.header-left a {
  width: 0px !important;
  height: 0px !important;
  font-size: 0px;
}

.header-left img {
  margin-top: 4px;
}

.header-logo-text {
  margin-left: 19px;
  font-family: "agency", sans-serif;
  font-size: 30px;
  color:#dddddd;
}

.links-left {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  justify-content: center;
}

.links {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  border-bottom: 1px solid transparent;
  transition: 0.2s;
}

/*.links:has(> a:hover),*/
.links:hover,
.links:has(.mediamo:hover) {
  border-bottom: 1px solid #437cb2;
  background-color: #2f2f2f;
}

.mediamo {
  width: 120px;
  padding-top: 10px;
  position: absolute;
  top: 80px;
  z-index: -1;
  opacity: 0;
  transition: 0.4s;
}

.media-menu {
  width: 120px;
  padding: 10px 0;
  height: auto;
  background-color: #282828;
  border: 1px solid #808080;
}

.media-dd {
  display: contents;
}

.media-menu div {
  width: 118px;
  height: 27px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "fetropolis", sans-serif;
  font-size: 10px;
  color: #808080;
  transition: 0.2s;
}

.media-menu div:hover {
  background-color: #2f2f2f;
  color: #dddddd;
}

.mediahl:hover + .mediamo,
.mediamo:hover {
  opacity: 1;
  z-index: 3;
}

.header-right {
  width: auto;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 50px;
}

.header-right a {
  font-family: "fetropolis", sans-serif;
  user-select: none;
  text-decoration: none;
  display: inline-flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  padding: none;
  color: #808080;
  transition: 0.2s;
  filter: brightness(1) !important;
}

.links > a {
  padding: 0 21px 0 20px;
}

.header-right a:hover {
  color: #dddddd !important;
}

.links-right {
  margin-top: -2px;
  z-index: 2;
}
