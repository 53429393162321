html:has(#gallery[data-open="true"]) {
  overflow: clip;
}

#gallery {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 102;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: hsla(0deg, 0%, 0%, 85%);
  backdrop-filter: blur(10px);
  padding: 174px calc(50% - 599px) 0;
}

#gallery[data-open="initial-closed"] {
  z-index: -10;
  opacity: 0;
}

#gallery[data-open="initial-open"] {
  z-index: 102;
  opacity: 1;
}

#gallery[data-open="true"] {
  animation: open 0.4s forwards;
}

@keyframes open {
  from {
    z-index: -10;
    opacity: 0;
  }
  to {
    z-index: 102;
    opacity: 1;
  }
}

#gallery[data-open="false"] {
  animation: close 0.4s forwards;
}

@keyframes close {
  from {
    z-index: 102;
    opacity: 1;
  }
  to {
    z-index: -10;
    opacity: 0;
  }
}

#gallery > h4 {
  font-family: "agency", sans-serif;
  font-size: 28px;
  color: #dddddd;
  font-weight: normal;
  line-height: 1;
  margin: -2px 0 4px;
}

#gallery > button.composer {
  font-family: "fetropolis", sans-serif;
  font-size: 10px;
  color: #808080;
  font-weight: normal;
  line-height: 1;
  margin: 4px 0 18px;
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  transition: 0.2s color;
  width: fit-content;
}

#gallery > button.composer:hover {
  color: #437cb2;
}

#gallery > #gallery-image-container {
  width: 100%;
  aspect-ratio: 16 / 9;
  position: relative;
}

#gallery > #gallery-image-container .gallery-image {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

#gallery > #gallery-image-container .gallery-image img {
  display: block;
  max-width: 100%;
  height: auto;
  background-color: transparent;
  position: relative;
  left: 0;
  opacity: 0;
}

#gallery > #gallery-image-container .gallery-image[data-selected="true"] {
  z-index: 2;
}

#gallery > #gallery-image-container .gallery-image[data-selected="true"] img {
  opacity: 1;
}

#gallery > .index {
  font-family: "agency", sans-serif;
  font-size: 36px;
  color: #dddddd;
  line-height: 1;
  margin: 18px 0 74px;
  text-align: right;
}

#gallery .description {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: #1c1c1c;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 20px;
  transition: 0.4s;
  cursor: pointer;
  position: relative;
}

#gallery .description[data-expanded="true"] {
  cursor: default;
}

#gallery .description .top {
  font-size: 10px;
  color: #808080;
  font-family: "fetropolis", sans-serif;
  line-height: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 1px;
}

#gallery .description > p {
  color: #dddddd;
  height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 11px;
  font-family: "fetropolis", sans-serif;
  transition: 0.4s;
  margin-bottom: -4px;
}

#gallery .description > p p {
  padding: 6px;
  margin: 0 -6px;
}

#gallery .description p a {
  color: #adadad;
  transition: 0.2s color;
}

#gallery .description > p .expand-button {
  position: absolute;
  top: 13px;
  right: 13px;
  padding: 6px;
  border: 0;
  background: none;
  display: flex;
  align-items: center;
  color: #808080;
  font-size: 9px;
  font-family: "fetropolis", sans-serif;
  line-height: 1;
  transition: 0.2s color;
  cursor: pointer;
}

#gallery .description > p .expand-button:hover {
  color: #dddddd;
}

#gallery .description > p .expand-button span {
  position: absolute;
  top: 7px;
  left: calc(-4px - var(--width, 0));
  width: max-content;
  display: block;
}

#gallery .description[data-expanded="true"] p {
  color: #dddddd;
}

#gallery .social-media-icons {
  display: flex;
  margin: 41px auto 47px;
  width: fit-content;
}

#gallery .social-media-img {
  margin: 0 5px;
  height: 30px;
  border-radius: 3px;
  overflow: hidden;
}

#gallery .social-media-img img {
  transition: transform 0.4s cubic-bezier(0.55, 0.085, 0, 0.99);
}

#gallery .social-media-img img:hover {
  transform: translateY(-30px);
}

#gallery .description .bottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

#gallery .description .bottom .copyright {
  width: fit-content;
  display: block;
  font-size: 10px;
  color: #808080;
  font-family: "fetropolis", sans-serif;
  line-height: 8px;
}

#gallery .description .copyright a {
  color: #808080;
}

#gallery .description .copyright a:hover {
  color: #437cb2;
}

#gallery .description .bottom .buttons {
  display: flex;
}

#gallery .description .bottom .buttons button {
  width: auto;
  height: auto;
  border: 0;
  background-color: transparent;
  padding: 0;
  padding: 4px;
  margin: -4px -4px -4px 16px;
  color: #dddddd;
  transition: 0.2s color;
  cursor: pointer;
}

#gallery .description .bottom .buttons button:hover {
  color: #437cb2;
}

#gallery #galleryClose,
#gallery #galleryPrevious,
#gallery #galleryNext {
  position: absolute;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  color: #dddddd;
  transition: 0.2s color;
  cursor: pointer;
}

#gallery #galleryClose:hover,
#gallery #galleryPrevious:hover,
#gallery #galleryNext:hover {
  color: #437cb2;
}

#gallery #galleryPrevious[data-disabled="true"],
#gallery #galleryNext[data-disabled="true"] {
  color: #808080;
}

#gallery #galleryClose {
  top: 50px;
  right: 50px;
}

#gallery #galleryPrevious,
#gallery #galleryNext {
  top: calc(50% + 1px);
  transform: translateY(-50%);
}

#gallery #galleryPrevious {
  left: calc(-50vw + 50% + 50px);
}

#gallery #galleryNext {
  right: calc(-50vw + 50% + 50px);
}