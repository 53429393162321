footer {
  width: 100%;
  height: 81px;
  border-top: 1px solid #808080;
  padding: 0 calc(50% - 599px);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: #282828;
  font-family: 'nimbus ultra light', arial, Helvetica, sans-serif;
  text-decoration: none;
  font-size: 11px;
  color: #808080;
  align-self: baseline;
  margin-top: auto;
}

footer a {
  font-family: 'nimbus ultra light', arial, Helvetica, sans-serif;
  text-decoration: none;
  font-size: 11px;
  color: #808080;
  transition: 0.2s;
}

footer a:hover {
  color: #dddddd !important;
}

footer > .footer-left a:hover {
  color: #437cb2 !important;
}

.footer-seperator {
  width: 1px;
  height: 10px;
  margin: 0 10px;
  background-color: #808080;
}

footer svg {
  margin-left: 6px;
  width: 12px;
  height: 12px;
  stroke: #437cb2;
  transition: 0.5s;
}

.footer-right a:hover svg {
  transform: translateY(-5px);
}

footer > div:nth-child(1) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

footer > div:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
}

footer > div:nth-child(3) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  top: -1px;
}

footer > div:nth-child(3) > a:first-child {
  position: relative;
  top: 1px;
}
