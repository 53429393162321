#playing-video {
  position: relative;
}

#playing-video .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

#playing-video .header div:first-child h4 {
  font-size: 12px;
  color: #dddddd;
  font-family: "fetropolis", sans-serif;
  font-weight: bold;
  line-height: 10px;
  margin-bottom: 10px;
}

#playing-video .header div:first-child a {
  display: block;
  font-size: 10px;
  font-family: "fetropolis", sans-serif;
  line-height: 7px;
}

#playing-video .header div:last-child {
  font-size: 10px;
  color: #808080;
  font-family: "fetropolis", sans-serif;
  text-align: center;
  line-height: 12px;
}

#playing-video .header a {
  color: #808080;
  text-decoration: none;
  transition: 0.2s;
  cursor: pointer;
  width: fit-content;
}

#playing-video .header a:hover {
  color: #437cb2;
}

#playing-video .video-container {
  width: 100%;
  height: 674px;
  position: absolute;
  overflow: hidden;
  border-radius: 4px;
}

#playing-video .video-container:focus {
  outline: 0;
}

#playing-video .video-space {
  width: 100%;
  height: auto;
  height: 674px;
}

#playing-video .video-box {
  width: 100%;
  height: 100%;
}

#playing-video .video-box video {
  width: 100%;
  height: 100%;
}

#playing-video #thumbnail {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: 0.2s;
  object-fit: cover;
}

#playing-video .video-container:hover #thumbnail {
  filter: brightness(0.6);
}

#playing-video .watch-button {
  width: 60px;
  height: 30px;
  border-radius: 4px;
  background-color: #dddddd;
  color: #1c1c1c;
  transition: 0.2s;
  cursor: pointer;
  position: absolute;
  left: 20px;
  bottom: 20px;
  font-family: "fetropolis bold", sans-serif;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 12px;
}

#playing-video .watch-button:hover {
  background-color: #437cb2;
}

#playing-video .watch-time {
  position: absolute;
  right: 20px;
  top: 17px;
  font-family: "fetropolis", sans-serif;
  font-size: 12px;
  color: #dddddd;
  pointer-events: none;
}

#playing-video .view-album {
  position: absolute;
  right: 13px;
  bottom: 13px;
  line-height: 9px;
  font-size: 10px;
  padding: 6px;
  background: transparent;
  font-family: "fetropolis", sans-serif;
  color: #dddddd;
  cursor: pointer;
}

#playing-video .view-album:hover {
  font-weight: bold;
}

#playing-video #play_pause_effect {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: pp 1s;
}

@keyframes pp {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

#playing-video #play_pause_effect::before {
  content: "";
  position: absolute;
  display: block;
  aspect-ratio: 1;
  width: 64px;
  background-color: #ffffff;
  opacity: 30%;
  border-radius: 50%;
}

#playing-video #play_pause_effect svg {
  position: absolute;
}

#playing-video #play_pause_effect[data-playing="false"] svg path:first-child {
  d: path("M11 7L0 0V28L11 21V1Z");
  transition: 0.4s d;
}

#playing-video #play_pause_effect[data-playing="false"] svg path:last-child {
  d: path("M22 14L11 7V21L22 14Z");
  transition: 0.4s d;
}

#playing-video #play_pause_effect[data-playing="true"] svg path:first-child {
  d: path("M8 0L0 0V28L8 28V7Z");
  transition: 0.4s d;
}

#playing-video #play_pause_effect[data-playing="true"] svg path:last-child {
  d: path("M22 0L14 0V28L22 28Z");
  transition: 0.4s d;
}

#playing-video #video-overlay {
  opacity: 0;
  transition: 0.4s;
}

#playing-video #video-overlay[data-show="true"] {
  opacity: 1;
  transition: 0.4s;
}

#playing-video #video-volume-change {
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  opacity: 0;
  background: #0008;
  transition: 0.4s;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dddddd;
  font-size: 16px;
  font-family: "fetropolis", sans-serif;
}

#playing-video #video-overlay > p {
  font-family: "fetropolis", sans-serif;
  color: #dddddd;
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 18px 20px 0;
  pointer-events: none;
}

#playing-video #video-overlay .options {
  width: auto;
  height: auto;
  position: absolute;
  top: 20px;
  right: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: right 0.6s;
}

#playing-video #video-overlay .options button.option {
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0 0 10px;
  border: 0;
  border-radius: 4px;
  background: #1c1c1c;
  transition: background 0.2s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

#playing-video #video-overlay .options button.option:hover {
  background: #3f3f3f;
}

#playing-video #video-overlay .options button.option[data-liked="true"] {
  background: #3d5974;
}

#playing-video #video-overlay .options button.option[data-disliked="true"] {
  background: #743d3d;
}

#playing-video #video-overlay .options button.option::after {
  content: attr(aria-label);
  position: absolute;
  right: 40px;
  font-family: "fetropolis", sans-serif;
  font-size: 10px;
  font-weight: bold;
  color: #dddddd;
  opacity: 0;
  transition: opacity 0.2s;
}

#playing-video #video-overlay .options button.option:hover::after {
  opacity: 1;
}

#playing-video #video-player {
  width: 100%;
  height: 92px;
  padding-top: 46px;
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  left: 0;
  bottom: -92px;
  transition: 0.6s;
  background: linear-gradient(to bottom, #0000, #0008);
}

/*#playing-video #video-player::before,
#playing-video #video-player::after {
  position: absolute;
  top: 14px;
  font-family: "fetropolis", sans-serif;
  font-size: 12px;
  color: #dddddd;
  pointer-events: none;
  transition: opacity 0.2s;
}

*#playing-video #video-player::before {
  content: attr(data-time);
  left: 20px;
}

#playing-video #video-player::after {
  content: attr(data-duration);
  right: 20px;
}*/

#playing-video #video-player .video-timeline-outer {
  width: 100%;
  height: auto;
  padding: 10px 0;
  margin: -10px 0;
  position: relative;
  z-index: 10;
  cursor: pointer;
}

#playing-video #video-player .video-timeline-outer:hover {
  padding: 9px 0;
}

#playing-video #video-player .video-timeline {
  width: 100%;
  height: var(--height, 6px);
  background: #808080;
  position: relative;
}

#playing-video #video-player .video-timeline-outer:hover .video-timeline {
  height: calc(var(--height, 6px) + 2px);
}

#playing-video #video-player .video-timeline::before {
  content: "";
  display: block;
  position: relative;
  z-index: 2;
  height: 100%;
  width: var(--video-time, 0);
  background-color: var(--progress-colour, #437cb2);
  d: path("M22 0L14 0V28L22 28Z");
  pointer-events: none;
  transition: 0.1s;
}

#playing-video #video-player .video-timeline::after {
  content: "";
  display: none;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: calc(100% - var(--hover-position, 100%));
  pointer-events: none;
  background-color: var(--hover-colour, #2f577c);
  z-index: var(--hover-z-index, 3);
}

#playing-video #video-player .video-timeline-outer:hover .video-timeline::after {
  display: block;
}

#video-hover-time {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  bottom: 0;
  left: var(--hover-position, 0);
  /*background-color: #262626;*/
  display: none;
  z-index: 9;
}

#playing-video #video-player .video-timeline-outer:hover #video-hover-time {
  display: block;
}

#video-hover-time::after {
  content: attr(data-time);
  position: absolute;
  top: -31px;
  transform: translateX(-50%);
  font-family: "fetropolis", sans-serif;
  font-size: 12px;
  color: #dddddd;
  background-color: #262626;
  padding: 0 4px;
  border-radius: 4px;
  pointer-events: none;
}

#playing-video #video-player .controls {
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#playing-video #video-player .controls > * {
  display: flex;
  align-items: center;
}

#playing-video #video-player .controls .play-pause[data-playing="false"] svg path:first-child {
  d: path("M5 3.18L0 0V14L5 10.82Z");
  transition: 0.4s d;
}

#playing-video #video-player .controls .play-pause[data-playing="false"] svg path:last-child {
  d: path("M11 7L5 3.18V10.82L11 7Z");
  transition: 0.4s d;
}

#playing-video #video-player .controls .play-pause[data-playing="true"] svg path:first-child {
  d: path("M4 0L0 0V14L4 14Z");
  transition: 0.4s d;
}

#playing-video #video-player .controls .play-pause[data-playing="true"] svg path:last-child {
  d: path("M11 0L7 0V14L11 14Z");
  transition: 0.4s d;
}

#playing-video #video-player .controls .volume-outer {
  overflow: hidden;
  width: 22px;
  transition: width 0.4s;
}

#playing-video #video-player .controls .volume-outer:hover {
  width: 134px;
}

#playing-video #video-player .controls .volume-container {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 5px 0;
}

#playing-video #video-player .controls .volume-container .mute,
#playing-video #video-player .controls .volume-container .low,
#playing-video #video-player .controls .volume-container .high {
  opacity: 0;
  transition: 0.2s opacity;
}

#playing-video #video-player .controls .volume-container[data-volume-state="mute"] .mute {
  opacity: 1;
}

#playing-video #video-player .controls .volume-container[data-volume-state="low"] .low {
  opacity: 1;
}

#playing-video #video-player .controls .volume-container[data-volume-state="high"] .high {
  opacity: 1;
}

#playing-video #video-player .controls .volume-bar-outer {
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  margin: -10px 0;
  cursor: pointer;
}

#playing-video #video-player .controls .volume-bar {
  width: 100px;
  height: 6px;
  background: #808080;
  margin-left: 4px;
  cursor: pointer;
  position: relative;
  pointer-events: none;
}

#playing-video #video-player .controls .volume-bar::before {
  content: "";
  display: block;
  width: var(--width, 0);
  height: 100%;
  background: #437cb2;
  pointer-events: none;
  position: relative;
  z-index: 2;
}

#playing-video #video-player .controls .volume-bar::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: var(--hover-width, 0);
  background-color: var(--colour);
  display: none;
  z-index: var(--z-index, 0);
}

#playing-video #video-player .controls .volume-bar-outer:hover .volume-bar::after {
  display: block;
}

#playing-video #video-player .controls .volume-bar-outer::after {
  content: attr(data-volume) "%";
  display: block;
  position: absolute;
  top: 22px;
  right: 0;
  font-family: "fetropolis", sans-serif;
  font-size: 12px;
  color: #dddddd;
}

#playing-video #video-player .controls .video-times {
  margin-left: 21px;
  font-family: "fetropolis", sans-serif;
  font-size: 12px;
  color: #dddddd;
}

#video-autoplay .autoplay-icon {
  display: flex;
  width: 25px;
  height: 14px;
  border-radius: 8px;
  background-color: transparent;
  border: 2px solid #808080;
  padding: 1px;
  transition: 0.2s;
}

#video-autoplay .autoplay-icon::before {
  content: "";
  display: block;
  width: auto;
  transition: 0.2s;
  flex-grow: 0;
}

#video-autoplay .autoplay-icon::after {
  content: "";
  display: block;
  height: 100%;
  aspect-ratio: 1;
  border-radius: 8px;
  background-color: #808080;
  transition: 0.2s;
}

#video-autoplay[data-autoplay="true"] .autoplay-icon::before {
  flex-grow: 1;
}

#video-autoplay[data-autoplay="true"] .autoplay-icon {
  border: 2px solid #dddddd;
}

#video-autoplay[data-autoplay="true"] .autoplay-icon::after {
  background-color: #dddddd;
}

#video-autoplay:hover .autoplay-icon {
  border: 2px solid #437cb2;
}

#video-autoplay:hover .autoplay-icon::after {
  background-color: #437cb2;
}

#playing-video .details {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 8px 0 7px;
}

#playing-video .details .left {
  display: flex;
  align-items: center;
  margin-left: 14px;
}

#playing-video .details .left button[title] {
  display: flex;
  width: auto;
  height: auto;
  padding: 6px;
  background: transparent;
  border: 0;
  transition: 0.2s;
  cursor: pointer;
  color: #808080;
}

#playing-video .details .left button[title]:hover {
  color: #dddddd;
}

#playing-video .details .left button[title="YouTube"]:hover > svg {
  fill: #ff0000;
}

#playing-video .details > .left .menu {
  width: 160px;
  height: auto;
  padding: 10px 0;
  background-color: #363636;
  border-radius: 4px;
  position: absolute;
  z-index: 2;
  top: 7px;
  left: 37px;
  display: none;
}

#playing-video .details > .left .menu[data-open="true"] {
  display: block;
}

#playing-video .details > .left .menu button {
  width: 100%;
  height: 27px;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "proxima", sans-serif;
  font-size: 10px;
  font-weight: bold;
  color: #dddddd;
  cursor: pointer;
  text-align: left;
}

#playing-video .details > .left .menu button:hover {
  background-color: #3f3f3f;
}

#playing-video .details .right {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

#playing-video .details .positive,
#playing-video .details .negative {
  display: inline-flex;
  align-items: center;
  position: relative;
  color: #808080;
  font-family: "fetropolis", sans-serif;
  font-size: 12px;
}

#playing-video .details .positive span {
  position: absolute;
  left: 26px;
  top: 0;
}

#playing-video .details .negative span {
  position: absolute;
  right: 26px;
  top: 0;
}

#playing-video .details .positive {
  margin-right: 70px;
}

#playing-video .details .thumbs-up,
#playing-video .details .thumbs-down {
  padding: 4px;
  margin: -4px;
  display: inline-flex;
  border: 0;
  background: transparent;
  color: #808080;
  cursor: pointer;
  transition: 0.2s;
}

#playing-video .details .thumbs-up:hover,
#playing-video .details .thumbs-down:hover {
  color: #dddddd;
  /*background: hsl(0deg 0% 100% / 10%);*/
}

#playing-video .details .share,
#playing-video .details .options {
  padding: 4px;
  display: inline-flex;
  border: 0;
  background: transparent;
  color: #808080;
  cursor: pointer;
  transition: 0.2s;
}

#playing-video .details .share {
  margin-right: 12px;
}

#playing-video .details .options {
  margin-right: -4px;
}

#playing-video .details .share:hover,
#playing-video .details .options:hover {
  color: #dddddd;
  /*background: hsl(0deg 0% 100% / 10%);*/
}

#playing-video .details > .right .menu {
  width: 160px;
  height: auto;
  padding: 10px 0;
  background-color: #363636;
  border-radius: 4px;
  position: absolute;
  z-index: 2;
  top: 5px;
  right: 18px;
  display: none;
}

#playing-video .details > .right .menu[data-open="true"] {
  display: block;
}

#playing-video .details > .right .menu button {
  width: 100%;
  height: 27px;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "proxima", sans-serif;
  font-size: 10px;
  font-weight: bold;
  color: #dddddd;
  cursor: pointer;
  text-align: left;
}

#playing-video .details > .right .menu button:hover {
  background-color: #3f3f3f;
}

#playing-video .description {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border: 1px solid #2a2a2a;
  background: #1c1c1c;
  border-radius: 5px;
  padding: 20px;
  transition: 0.4s;
  cursor: pointer;
  position: relative;
}

#playing-video .description[data-expanded="true"] {
  cursor: default;
}

#playing-video .description .top {
  font-size: 10px;
  color: #808080;
  font-family: "fetropolis", sans-serif;
  line-height: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 1px;
}

#playing-video .description > p {
  color: #dddddd;
  height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 11px;
  font-family: "fetropolis", sans-serif;
  transition: 0.4s;
  margin-bottom: -4px;
}

#playing-video .description > p p {
  padding: 6px;
  margin: 0 -6px;
}

#playing-video .description p a {
  color: #adadad;
  transition: 0.2s color;
}

#playing-video .description > p .expand-button {
  position: absolute;
  top: 13px;
  right: 13px;
  padding: 6px;
  border: 0;
  background: none;
  display: flex;
  align-items: center;
  color: #808080;
  transition: 0.2s color;
  font-size: 9px;
  font-family: "fetropolis", sans-serif;
  line-height: 1;
  cursor: pointer;
}

#playing-video .description > p .expand-button:hover {
  color: #dddddd;
}

#playing-video .description > p .expand-button span {
  position: absolute;
  top: 7px;
  left: calc(-4px - var(--width, 0));
  width: max-content;
  display: block;
}

#playing-video .description[data-expanded="true"] p {
  color: #dddddd;
}

#playing-video .social-media-icons {
  display: flex;
  margin: 41px auto 47px;
  width: fit-content;
}

#playing-video .social-media-img {
  margin: 0 5px;
  height: 30px;
  border-radius: 3px;
  overflow: hidden;
}

#playing-video .social-media-img img {
  transition: transform 0.4s cubic-bezier(0.55, 0.085, 0, 0.99);
}

#playing-video .social-media-img img:hover {
  transform: translateY(-30px);
}

#playing-video .description .description-link {
  width: fit-content;
  display: block;
}

#playing-video .description .copyright {
  font-size: 10px;
  color: #808080;
  font-family: "fetropolis", sans-serif;
  line-height: 8px;
  display: flex;
  align-items: center;
  opacity: 1 !important;
}

#playing-video .description .copyright a {
  color: #808080;
}

#playing-video .description .copyright a:hover {
  color: #437cb2;
}