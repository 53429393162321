#album-gallery {
  margin-top: 80px;
}

#album-gallery .gallery-slider {
  position: relative;
  left: -5px;
  width: 1208px;
}

#album-gallery .all-images {
  margin-top: 50px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 35px;
}