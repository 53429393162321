#latest-albums {
  position: relative;
  width: 1208px;
  left: -5px;
  margin-top: -57px;
}

#latest-albums .subtitle {
  text-align: center;
  font-family: "orbitron";
  font-size: 14px;
  line-height: 10px;
  color: #dddddd;
  margin: 119px 0 45px;
}

#latest-albums .album-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

#latest-albums .album-loader .loading-album {
  width: 302px;
  height: 483px;
  padding: 5px;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  background: transparent;
  transition: background 0.2s;
}

#latest-albums .album-loader .loading-album:hover {
  background: #2f2f2f;
}

#latest-albums .album-loader .loading-album_image {
  width: 292px;
  height: 438px;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  justify-content: flex-end;
  background: #363636;
  animation: load 2s infinite;
}

#latest-albums .album-loader .loading-album_title {
  width: 40%;
  height: 10px;
  border-radius: 4px;
  background: #363636;
  animation: load 2s infinite;
  margin: 10px 0 5px;
}

#latest-albums .album-loader .loading-album_composer {
  width: 30%;
  height: 8px;
  border-radius: 4px;
  background: #363636;
  animation: load 2s infinite;
}
