#latest-videos {
  position: relative;
}

.playing-video-loader {
  width: 100%;
  height: 812px;
  margin-bottom: 50px;
}

.playing-video-loader > div {
  display: flex;
  justify-content: space-between;
}

.playing-video-loader .loading-title {
  width: 360px;
  height: 11px;
  border-radius: 4px;
  background: #363636;
  animation: load 2s infinite;
}

.playing-video-loader .loading-album {
  width: 120px;
  height: 8px;
  margin: 9px 0;
  border-radius: 4px;
  background: #363636;
  animation: load 2s infinite;
}

.playing-video-loader .loading-copyright {
  width: 80px;
  height: 25px;
  border-radius: 4px;
  background: #363636;
  animation: load 2s infinite;
}

.playing-video-loader .loading-main-video {
  width: 100%;
  height: 674px;
  border-radius: 4px;
  background: #363636;
  animation: load 2s infinite;
}

.playing-video-loader .loading-icons {
  width: 180px;
  height: 20px;
  margin: 10px 0;
  border-radius: 4px;
  background: #363636;
  animation: load 2s infinite;
}

.playing-video-loader .loading-icons:first-child {
  width: 120px;
}

.playing-video-loader .loading-description {
  width: 100%;
  height: 62px;
  border-radius: 4px;
  background: #363636;
  animation: load 2s infinite;
}

.video-loader {
  width: calc(100% + 10px);
  height: 100%;
  position: relative;
  left: -5px;
  display: flex;
  justify-content: space-between;
}

.video-loader .loading-video {
  width: 302px;
  height: 209px;
  padding: 5px;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  background: transparent;
  transition: background 0.2s;
}

.video-loader .loading-video:hover {
  background: #2f2f2f;
}

.video-loader .loading-video_image {
  width: 292px;
  height: 164px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 4px;
  background: #363636;
  animation: load 2s infinite;
}

.video-loader .loading-video_title {
  width: 40%;
  height: 10px;
  border-radius: 4px;
  background: #363636;
  animation: load 2s infinite;
  margin: 10px 0 5px;
}

.video-loader .loading-video_composer {
  width: 30%;
  height: 8px;
  border-radius: 4px;
  background: #363636;
  animation: load 2s infinite;
}
