body {
  overflow: hidden;
}

.wrapper {
  background-color: #282828;
  min-height: 100vh;
}

main {
  width: 1198px;
  margin-bottom: 140px;
}
