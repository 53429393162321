input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 40px #282828 inset !important;
  color: #dddddd !important;
}

input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

.search {
  width: 100%;
  height: 41px;
  border-bottom: 1px solid #808080;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.search #search {
  font-family: "fetropolis", sans-serif;
  font-size: 10px;
  text-transform: uppercase;
  width: 1198px;
  height: 40px;
  border: 0;
  background: transparent;
  padding-left: 25px;
  color: #dddddd;
  outline: none;
  appearance: none;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23dddddd'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/></svg>");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: -2px 50%;
}

.search .clear {
  width: 26px;
  height: 26px;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23808080'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z'/></svg>");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 7px 50%;
  cursor: pointer;
  position: absolute;
  right: calc(50% - 599px);
  transition: 0.5s;
}

.search .clear:hover {
  filter: brightness(1.73);
}

.search .results {
  width: 1198px;
  height: auto;
  max-height: 500px;
  overflow: auto;
  position: absolute;
  top: 61px;
  background-color: #282828;
  z-index: 10;
  border: 1px solid #808080;
  display: none;
  scrollbar-width: thin;
  scrollbar-color: #808080 #282828;
}

.search #search:focus ~ .results[data-visible="true"],
.search .results[data-visible="true"]:active {
  display: block;
}

.search .results > div {
  width: 100%;
  border-top: 1px solid #808080;
  padding: 10px;
}

.search .results > div:first-child {
  border-top: 0;
}

.search .results * {
  font-family: "proxima", sans-serif;
}

.search .results h4 {
  font-size: 12px;
  line-height: 9px;
  color: #dddddd;
  font-weight: normal;
  position: relative;
  top: 1px;
  margin-bottom: 11px;
}

.search .results .non {
  width: auto;
  margin: 0 -10px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #808080;
}

.search .results .result {
  width: auto;
  margin: 0 -10px;
  height: 60px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search .results .result:hover {
  background-color: #2f2f2f;
}

.search .results .result > .left {
  display: flex;
  align-items: center;
}

.search .results .result > .left .details {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.search .results .result > .left .details .title {
  color: #dddddd;
  font-size: 12px;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 5px;
  transition: color 0.2s;
  width: fit-content;
}

.search .results .result > .left .details .title:hover {
  color: #437cb2;
}

.search .results .result > .left .details .subtitle {
  color: #808080;
  font-size: 10px;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.2s;
  width: fit-content;
}

.search .results .result > .left .details .subtitle:hover {
  color: #437cb2;
}

.search .results .result > .right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-size: 10px;
  color: #808080;
}

.search .results .result > .right span:first-child {
  margin-bottom: 6px;
  color: #dddddd;
}