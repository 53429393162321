#player {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  position: fixed;
  bottom: -68px;
  left: 0;
  right: 0;
  background-color: #363636;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  transition: 0.2s bottom;
}

#player,
#player * {
  outline: 0; /* bad for UX & accessibility */
}

#player::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  right: 0;
  background-color: #363636;
  z-index: -1;
}

.control-button {
  display: flex;
  width: auto;
  height: auto;
  padding: 6px;
  background: transparent;
  border: 0;
  transition: 0.2s background;
  cursor: pointer;
}

.control-button > svg,
.control-button > svg path {
  transition: 0.2s;
}

/*.control-button:hover {
  background: #3f3f3f;
}*/

.control-button:hover > svg {
  fill: #437cb2;
}

.control-button[data-disabled="true"] {
  cursor: not-allowed;
  background: transparent;
}

.control-button[data-disabled="true"] > svg {
  fill: #505050;
}

.vertical-divider {
  width: 1px;
  height: 32px;
  background-color: #808080;
  margin: 0 24px;
}

#player > .left {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 14px;
}

#player > .menu,
#player > .options {
  width: auto;
  height: auto;
  position: absolute;
  top: -98px;
  display: none;
  flex-direction: column;
  align-items: center;
  transition: right 0.6s;
  transform: translateY(-100%);
}

#player > .menu[data-open="true"],
#player > .options[data-open="true"] {
  display: flex;
}

#player > .menu {
  left: 20px;
}

#player > .options{
  right: 20px;
}

#player > .menu button.option,
#player > .options button.option {
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0 0 10px;
  border: 0;
  border-radius: 4px;
  background: #1c1c1c;
  transition: background 0.2s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

#player > .menu button.option:hover,
#player > .options button.option:hover {
  background: #3f3f3f;
}

#player > .menu button.option[data-liked="true"],
#player > .options button.option[data-liked="true"] {
  background: #3d5974;
}

#player > .menu button.option[data-disliked="true"],
#player > .optoins button.option[data-disliked="true"] {
  background: #743d3d;
}

#player > .menu button.option::after,
#player > .options button.option::after {
  content: attr(aria-label);
  position: absolute;
  font-family: "fetropolis", sans-serif;
  font-size: 10px;
  font-weight: bold;
  color: #dddddd;
  opacity: 0;
  transition: opacity 0.2s;
}

#player > .menu button.option::after {
  left: 40px;
}

#player > .options button.option::after {
  right: 40px;
}

#player > .menu button.option:hover::after,
#player > .options button.option:hover::after {
  opacity: 1;
}

#playing-video #video-player {
  width: 100%;
  height: 92px;
  padding-top: 46px;
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  left: 0;
  bottom: -92px;
  transition: 0.6s;
  background: linear-gradient(to bottom, #0000, #0008);
}

#player .album-thumbnail {
  margin: 0 5px 0 11px;
}

#player > .left .playing-details {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 1;
  font-family: "fetropolis", sans-serif;
  color: #dddddd;
  text-transform: uppercase;
  background: transparent;
  border: 0;
  transition: 0.2s;
  padding: 5px 6px 4px;
  cursor: pointer;
}

#player > .left .playing-details:hover {
  background: hsl(0deg 0% 100% / 10%);
}

#player > .left .playing-details > *:not(:first-child) {
  font-size: 10px;
  color: #808080;
  background: transparent;
  transition: 0.2s;
}

#player > .left .playing-details a:last-child:hover {
  color: #437cb2;
}

#player > .center {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

#youtube:hover > svg {
  fill: #ff0000;
}

#play-pause[data-playing="false"] svg path:first-child {
  d: path("M11 7L0 0V28L11 21V1Z");
  transition: 0.4s d;
}

#play-pause[data-playing="false"] svg path:last-child {
  d: path("M22 14L11 7V21L22 14Z");
  transition: 0.4s d;
}

#play-pause[data-playing="true"] svg path:first-child {
  d: path("M8 0L0 0V28L8 28V7Z");
  transition: 0.4s d;
}

#play-pause[data-playing="true"] svg path:last-child {
  d: path("M22 0L14 0V28L22 28Z");
  transition: 0.4s d;
}

#autoplay .autoplay-icon {
  display: flex;
  width: 25px;
  height: 14px;
  border-radius: 8px;
  background-color: transparent;
  border: 2px solid #808080;
  padding: 1px;
  transition: 0.2s;
}

#autoplay .autoplay-icon::before {
  content: "";
  display: block;
  width: auto;
  transition: 0.2s;
  flex-grow: 0;
}

#autoplay .autoplay-icon::after {
  content: "";
  display: block;
  height: 100%;
  aspect-ratio: 1;
  border-radius: 8px;
  background-color: #808080;
  transition: 0.2s;
}

#autoplay[data-autoplay="true"] .autoplay-icon::before {
  flex-grow: 1;
}

#autoplay[data-autoplay="true"] .autoplay-icon {
  border: 2px solid #dddddd;
}

#autoplay[data-autoplay="true"] .autoplay-icon::after {
  background-color: #dddddd;
}

#autoplay:hover .autoplay-icon {
  border: 2px solid #437cb2;
}

#autoplay:hover .autoplay-icon::after {
  background-color: #437cb2;
}

#player > .right {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 14px;
}

#player > .left .volume-outer {
  overflow: hidden;
  width: 30px;
  transition: width 0.4s;
}

#player > .left .volume-outer:hover {
  width: 134px;
}

#player > .left .volume-container {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 5px 0;
}

#player > .left .volume-container .mute,
#player > .left .volume-container .low,
#player > .left .volume-container .high {
  opacity: 0;
  transition: 0.2s opacity;
}

#player > .left .volume-container[data-volume-state="mute"] .mute {
  opacity: 1;
}

#player > .left .volume-container[data-volume-state="low"] .low {
  opacity: 1;
}

#player > .left .volume-container[data-volume-state="high"] .high {
  opacity: 1;
}

#player > .left .volume-bar-outer {
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  margin: -10px 0;
  cursor: pointer;
}

#player > .left .volume-bar {
  width: 100px;
  height: 6px;
  background: #808080;
  margin-left: 4px;
  cursor: pointer;
  position: relative;
  pointer-events: none;
}

#player > .left .volume-bar::before {
  content: "";
  display: block;
  width: var(--width, 0);
  height: 100%;
  background: #437cb2;
  pointer-events: none;
  position: relative;
  z-index: 2;
}

#player > .left .volume-bar::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: var(--hover-width, 0);
  background-color: var(--colour);
  display: none;
  z-index: var(--z-index, 0);
}

#player > .left .volume-bar-outer:hover .volume-bar::after {
  display: block;
}

#player > .left .volume-bar-outer::after {
  content: attr(data-volume) "%";
  display: block;
  position: absolute;
  top: 24px;
  right: 0;
  font-family: "fetropolis", sans-serif;
  font-size: 12px;
  color: #dddddd;
}

#player > .right #share-icon {
  margin: 0 8px;
}

#player > .progress-bar {
  width: 100%;
  height: 60px;
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
  background-color: #808080;
  z-index: -2;
  transition: bottom 0.8s;
  cursor: pointer;
}

#player:hover > .progress-bar[data-can-open="true"] {
  bottom: 60px;
}

#player > .progress-bar::before,
#player > .progress-bar::after {
  position: absolute;
  top: -31px;
  font-family: "fetropolis", sans-serif;
  font-size: 12px;
  color: #dddddd;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
}

#player[data-open="true"] > .progress-bar::before,
#player[data-open="true"] > .progress-bar::after {
  opacity: 1;
}

#player > .progress-bar::before {
  content: attr(data-time);
  left: 20px;
}

#player > .progress-bar::after {
  content: attr(data-duration);
  right: 20px;
}

#progress {
  width: var(--progress, 0);
  height: 100%;
  background-color: var(--progress-colour, #437cb2);
  pointer-events: none;
  transition: width 0.1s linear;
}

#progress::after {
  content: "";
  display: none;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: calc(100% - var(--hover-position, 100%));
  pointer-events: none;
  background-color: var(--hover-colour, #2f577c);
  z-index: var(--hover-z-index, 0);
}

#player > .progress-bar:hover #progress::after {
  display: block;
}

#hover-time {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  bottom: 0;
  left: var(--hover-position, 0);
  /*background-color: #262626;*/
  display: none;
  z-index: 9;
}

#player > .progress-bar:hover > #hover-time {
  display: block;
}

#hover-time::after {
  content: attr(data-time);
  position: absolute;
  top: -31px;
  transform: translateX(-50%);
  font-family: "fetropolis", sans-serif;
  font-size: 12px;
  color: #dddddd;
  background-color: #282828;
  padding: 0 4px;
  border-radius: 4px;
  pointer-events: none;
}

.waveformContainer {
  margin: 8px 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.waveformContainer:nth-of-type(2) {
  transform: rotateX(180deg);
}
