.media-carousel {
  position: relative;
}

.media-carousel > .albums-arrow {
  position: absolute;
  top: 50%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  background: none;
  border: 0;
}

.media-carousel > .albums-arrow.left {
  transform: translate(-100%, -50%);
  left: -50px;
}

.media-carousel > .albums-arrow.right {
  transform: translate(100%, -50%);
  right: -50px;
}

.media-carousel > .albums-arrow > svg {
  transition: 0.2s;
}

.media-carousel > .albums-arrow:hover > svg {
  stroke: #dddddd;
}

.media-carousel .pages {
  display: flex;
  counter-reset: page;
  width: fit-content;
  padding: 0;
  margin: 0;
  margin-right: 5px;
  position: absolute;
  left: auto;
  right: 0;
  bottom: -20px;
  transform: translateY(100%);
}

.media-carousel .pages .page {
  width: 44px;
  height: 19px;
  background: #363636;
  padding: 0;
  margin: 0;
  margin-left: 10px;
  border: 0;
  transform: none !important;
  border-radius: 0;
  opacity: 1;
  font-family: "fetropolis", sans-serif;
  font-size: 10px;
  text-wrap: nowrap;
  color: #505050;
  line-height: 1;
  transition: 0.2s;
  cursor: pointer;
}

.media-carousel .pages .page[data-active="true"],
.media-carousel .pages .page:hover {
  color: #dddddd;
}
