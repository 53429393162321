.news-item {
  width: 100%;
  height: 43px;
  overflow: hidden;
  position: relative;
  border-top: 1px solid #dddddd;
  transition: height 0.4s;
  font-size: 12px;
}

.news-item:last-child {
  border-bottom: 1px solid #dddddd;
  height: 44px;
}

.news-item a {
  color: #1c1c1c !important;
  transition: 0.2s !important;
  position: initial !important;
  font-size: inherit !important;
}

.news-item a:hover {
  color: #437cb2 !important;
}

.date {
  font-family: "nimbus", sans-serif;
  color: #262626;
  width: 100%;
  padding: 14px 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.4s color;
}

.date:hover,
.date[data-open="true"] {
  color: #437cb2;
}

.date button {
  width: 16px;
  height: 16px;
  margin-top: -1px;
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  color: inherit;
}

.date svg {
  transform: rotate(-225deg);
  transition: transform 0.4s;
}

.news {
  font-size: 14px;
  position: absolute;
  margin: 0 0 15px !important;
}

.news span.space {
  display: block;
  margin-top: 10px;
}

.news h4 {
  font-family: "fetropolis bold", sans-serif;
  font-size: 13px;
  margin: 11px 0 14px;
}