#footer-links {
  display: flex;
  justify-content: space-between;
  width: 1198px;
  margin: 0 auto;
  border-top: 1px solid #808080;
}

#footer-links > div {
  margin: 50px 0 80px;
}

#footer-links h3 {
  color: #dddddd;
  font-family: "agency", sans-serif;
  font-weight: bold;
  font-family: 20px;
  line-height: 18px;
  margin-bottom: 12px;
}

#footer-links ul {
  list-style: none;
  padding: 0;
}

#footer-links a {
  font-family: "fetropolis", sans-serif;
  font-size: 10px;
  color: #808080;
  transition: 0.25s color;
}

#footer-links a:hover {
  color: #dddddd;
}