.video {
  width: 302px;
  border-radius: 4px;
  padding: 5px;
  background: transparent;
  transition: 0.2s background;
}

.video:hover {
  background: #2f2f2f;
}

.video .video-image {
  display: flex;
  position: relative;
  width: 292px;
  height: 164px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
}

.video .video-image > img {
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.2s;
}

.video:hover .video-image > img {
  opacity: 0;
}

.video .video-image > video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s;
}

.video:hover .video-image > video {
  opacity: 1;
}

.video .video-image > .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: hsl(0deg 0% 0% / 40%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  opacity: 0;
  transition: 0.2s opacity ease-in;
}

.video[data-playing="true"] .video-image > .overlay,
.video:hover .video-image > .overlay,
.video .overlay:has(.video_menu[data-open="true"]) {
  opacity: 1;
}

.video .video-image > .overlay > .top,
/*.video > .overlay > .name,*/
.video .video-image > .overlay > .bottom {
  width: 100%;
  height: auto;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s transform ease-in;
}

.video .video-image > .overlay > .top {
  transform: translateY(-5px);
}

.video .video-image > .overlay > .bottom {
  transform: translateY(5px);
}

.video[data-playing="true"] .video-image > .overlay > .top,
.video[data-playing="true"] .video-image > .overlay > .bottom,
.video:hover .video-image > .overlay > .top,
.video:hover .video-image > .overlay > .bottom {
  transform: translateY(0);
}

.video .video-image > .overlay > .top > button {
  width: auto;
  height: auto;
  padding: 6px;
  margin: 0;
  background: transparent;
  display: flex;
  border: 0;
  transition: 0.2s background;
  cursor: pointer;
  line-height: 9px;
  font-size: 10px;
  font-family: "fetropolis", sans-serif;
  color: #dddddd;
}

.video .video-image > .overlay > .top > .video-menu svg {
  transition: 0.2s fill;
}

/*.video > .overlay > .top > .video-menu:hover,
.video > .overlay > .top > .video-options:hover {
  background: hsl(0deg 0% 100% / 10%);
}*/

.video .video-image > .overlay > .top > .video-menu:hover svg,
.video .video-image > .overlay > .video_menu .close:hover svg {
  fill: #437cb2;
}

/*.video > .overlay > .name > span {
  text-align: center;
  margin: 0 auto;
  font-family: "agency", sans-serif;
  font-size: 20px;
  color: #dddddd;
}*/

.video .video-image > .overlay > .video_menu {
  width: 100%;
  height: 100%;
  background-color: #363636;
  position: absolute;
  top: 0;
  right: -100%;
  bottom: 0;
  left: 100%;
  cursor: default;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.2s;
}

.video .video-image > .overlay > .video_menu > div {
  display: flex;
}

.video .video-image > .overlay > .video_menu[data-open="true"] {
  right: 0;
  left: 0;
}

.video .video_menu .close {
  position: absolute;
  top: 4px;
  padding: 6px;
  background: transparent;
  margin: 0;
  border: 0;
  cursor: pointer;
}

.video .video_menu .close {
  left: 4px;
}

.video .video_menu h4 {
  font-family: "agency", sans-serif;
  font-size: 18px;
  text-align: center;
  font-weight: normal;
  color: #dddddd;
  margin: 16px 0 21px;
}

.video button.option {
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0 5px 0;
  border: 0;
  border-radius: 4px;
  background: #1c1c1c;
  transition: background 0.2s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.video button.option:hover {
  background: #3f3f3f;
}

.video button.option[data-liked="true"] {
  background: #3d5974;
}

.video button.option[data-disliked="true"] {
  background: #743d3d;
}

.video button.option::after {
  content: attr(aria-label);
  position: absolute;
  top: 38px;
  font-family: "fetropolis", sans-serif;
  font-size: 10px;
  font-weight: bold;
  color: #dddddd;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
}

.video button.option:hover::after {
  opacity: 1;
}

.video .video-image > .overlay > .bottom {
  position: relative;
}

.video .video-image > .overlay > .bottom > button {
  line-height: 9px;
  font-size: 10px;
  padding: 6px;
  background: transparent;
  font-family: "fetropolis", sans-serif;
  color: #dddddd;
  border: 0;
  cursor: pointer;
}

.video[data-playing="true"] .video-image > .overlay > .bottom > button.play-video span {
  display: inline-block;
  animation: waivy 3.6s infinite;
  animation-delay: calc(.1s * var(--i));
}

.video .video-image > .overlay > .bottom > button.play-video.in-to-out {
  position: absolute;
  pointer-events: none;
  animation: in-to-out ease-in 0.2s forwards;
}

.video .video-image > .overlay > .bottom > button.play-video.out-to-in {
  position: relative;
  opacity: 0;
  top: 5px;
  animation: out-to-in ease-in 0.2s forwards;
  animation-delay: 0.2s;
}

.video .video-image > .overlay > .bottom > button.play-video span,
.video .video-image > .overlay > .bottom > button.play-video:hover,
.video .video-image > .overlay > .bottom > button.view-video:hover {
  font-weight: bold;
}

.video > .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.video > .bottom > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.video > .bottom > div button {
  font-family: "fetropolis", sans-serif;
  line-height: 1;
  text-transform: uppercase;
  border: 0;
  padding: 0;
  cursor: pointer;
  margin: 0;
  background: transparent;
}

.video > .bottom button.title {
  margin: 9px 0 1px;
  color: #dddddd;
  font-size: 12px;
}

.video > .bottom button.composer {
  color: #808080;
  font-size: 10px;
  transition: 0.2s color;
}

.video > .bottom button.composer:hover {
  color: #437cb2;
}

.video > .bottom .video-share {
  padding: 4px;
  margin: 6px;
  display: inline-flex;
  border: 0;
  background: transparent;
  color: #dddddd;
  cursor: pointer;
  transition: 0.2s;
}

.video > .bottom .video-share:hover {
  color: #437cb2;
}
