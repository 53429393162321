.album-view {
  display: flex;
  position: relative;
  margin-top: 80px;
}

.album-view > .arrow {
  position: absolute;
  top: 50%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  background: none;
  border: 0;
}

.album-view > .arrow.left {
  transform: translate(-100%, -50%);
  left: -50px;
}

.album-view > .arrow.right {
  transform: translate(100%, -50%);
  right: -50px;
}

.album-view > .arrow > svg {
  transition: 0.2s;
}

.album-view > .arrow:hover > svg {
  stroke: #dddddd;
}

.album-view > img {
  width: 292px;
  height: 438px;
  border-radius: 4px;
  margin-right: 20px;
  flex-shrink: 0;
}

.album-view > div {
  margin-top: 20px;
  flex-grow: 1;
}

.album-view h3 {
  font-weight: normal;
  font-size: 22px;
  line-height: 16px;
  font-family: "fetropolis bold", sans-serif;
  color: #dddddd;
  margin-bottom: 10px;
  position: relative;
  top: 1px;
}

.album-view > div > a.composer {
  font-size: 10px;
  line-height: 7px;
  font-family: "fetropolis", sans-serif;
  color: #808080;
  margin-bottom: 47px;
  transition: 0.25s color;
  cursor: pointer;
  background-color: transparent;
  display: block;
  width: fit-content;
}

.album-view > div > a.composer:hover {
  color: #437cb2;
}

.album-view p.about {
  font-size: 11px;
  font-family: "fetropolis", sans-serif;
  color: #dddddd;
  margin-bottom: 39px;
}

.album-view p.details {
  margin-top: 10px;
  font-size: 10px;
  line-height: 7px;
  font-family: "fetropolis", sans-serif;
  color: #dddddd;
}

.album-view p.copyright {
  margin-top: 50px;
  font-size: 10px;
  line-height: 7px;
  font-family: "fetropolis", sans-serif;
  color: #808080;
}

.album-view p.copyright a {
  color: #808080;
  transition: 0.25s color;
}

.album-view p.copyright a:hover {
  color: #437cb2;
}

.album-view > div button {
  width: auto;
  height: 30px;
  border-radius: 4px;
  background-color: #1c1c1c;
  color: #dddddd;
  font-size: 10px;
  line-height: 7px;
  font-family: "fetropolis", sans-serif;
  margin: 40px 10px 0 0;
  border: 0;
  padding: 0 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
}

.album-view > div button svg {
  margin-right: 10px;
}

.album-view > div button:hover {
  background-color: #3f3f3f;
}

.album-view button.play {
  background-color: var(--accent-color);
}

.album-view > div div.buttons {
  display: flex;
}