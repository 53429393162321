.image {
  width: 302px;
  border-radius: 4px;
  padding: 5px;
  background: transparent;
  transition: 0.2s background;
}

.image:hover {
  background: #2f2f2f;
}

.image .thumbnail {
  display: flex;
  position: relative;
  width: 292px;
  height: 164px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
}

.image .thumbnail > img {
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.2s;
}

.image .thumbnail > .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: hsl(0deg 0% 0% / 40%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  opacity: 0;
  transition: 0.2s opacity ease-in;
}

.image:hover .thumbnail > .overlay {
  opacity: 1;
}

.image .thumbnail > .overlay > .top {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  transform: translateY(-5px);
  transition: 0.2s transform ease-in;
}

.image .thumbnail > .overlay > .top span {
  line-height: 9px;
  font-size: 10px;
  padding: 6px;
  background: transparent;
  font-family: "fetropolis", sans-serif;
  color: #dddddd;
  border: 0;
  pointer-events: none;
}

.image .thumbnail > .overlay > .bottom {
  width: 100%;
  height: auto;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translateY(5px);
  transition: 0.2s transform ease-in;
}

.image:hover .thumbnail > .overlay > .top,
.image:hover .thumbnail > .overlay > .bottom {
  transform: translateY(0);
}

.image .thumbnail > .overlay > .bottom {
  position: relative;
}

.image .thumbnail > .overlay > .bottom > span,
.image .thumbnail > .overlay > .bottom > button {
  line-height: 9px;
  font-size: 10px;
  padding: 6px;
  background: transparent;
  font-family: "fetropolis", sans-serif;
  color: #dddddd;
  border: 0;
  cursor: pointer;
}

.image .thumbnail > .overlay > .bottom > button:hover {
  font-weight: bold;
}

.image > .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image > .bottom > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.image > .bottom > div button {
  font-family: "fetropolis", sans-serif;
  line-height: 1;
  text-transform: uppercase;
  border: 0;
  padding: 0;
  cursor: pointer;
  margin: 0;
  background: transparent;
}

.image > .bottom button.title {
  margin: 9px 0 1px;
  color: #dddddd;
  font-size: 12px;
}

.image > .bottom button.composer {
  color: #808080;
  font-size: 10px;
  transition: 0.2s color;
}

.image > .bottom button.composer:hover {
  color: #437cb2;
}

.image > .bottom .image-share {
  padding: 4px;
  margin: 6px;
  display: inline-flex;
  border: 0;
  background: transparent;
  color: #dddddd;
  cursor: pointer;
  transition: 0.2s;
}

.image > .bottom .image-share:hover {
  color: #437cb2;
}
