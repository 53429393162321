#next-videos {
  position: relative;
  width: 1208px;
  left: -5px;
  margin-top: 50px;
}

#next-videos .video img {
  object-fit: cover;
}
