.testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  /*padding: 0 10px;*/
}

.testimonial-text {
  box-sizing: border-box;
  width: 100%;
  box-shadow: 0 5px 10px #1c1c1c12;
  background-color: white;
  padding: 16px 10px 33px;
  color: #1c1c1c;
  flex-grow: 1;
  line-height: 1.5;
  text-align: center;
  font-family: "nimbus light", Arial, Helvetica, sans-serif;
  border-radius: 5px;
  position: relative;
}

.testimonial-text::after {
  content: "";
  width: 0px;
  height: 0px;
  border-top: 15px solid white;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  position: absolute;
  bottom: -15px;
  left: calc(50% - 15px);
}

.testimonial-text > img {
  margin-top: -24px;
  position: relative;
  top: -7px;
}

.testimonial-text > img:last-of-type {
  transform: rotateY(180deg);
}

.testimonial-text > .testimonial-rating img {
  margin: 11px 0 5px;
}

.testimonial-date {
  font-size: 12px;
  color: #1c1c1c;
  position: absolute;
  line-height: 1;
  right: 10px;
  bottom: 10px;
}

.testimonial-name {
  width: fit-content !important;
  font-size: 14px !important;
  margin-top: 31px !important;
  color: #1c1c1c !important;
  font-family: "nimbus bold", Arial, Helvetica, sans-serif !important;
  position: relative !important;
  top: 0px !important;
}

.testimonial-name:hover {
  color: #437cb2 !important;
}

.testimonial-title {
  width: fit-content;
  font-size: 12px;
  margin: 2px 0 8px;
  color: #808080;
  font-family: "nimbus light", Arial, Helvetica, sans-serif;
}

.testimonial-progress {
  display: none;
  width: var(--width, 0);
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #437cb2;
}

.splide__slide.is-active .testimonial-progress {
  display: block;
}
