#error404 {
  width: 1198px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#error404 > img {
  margin: 80px 0 50px;
}

#error404 > h1 {
  font-family: "agency", sans-serif;
  font-size: 72px;
  color: #dddddd;
  margin-bottom: 10px;
  position: relative;
  top: 3px;
}

#error404 > p {
  font-family: "fetropolis bold", sans-serif;
  font-size: 12px;
  color: #dddddd;
  margin-top: 10px;
}

#error404 a {
  color: #437cb2;
  transition: 0.25s;
  cursor: pointer;
}

#error404 a:hover {
  color: #dddddd;
}

#error404 > div {
  width: 100%;
  height: auto;
  border-radius: 4px;
  background-color: #1c1c1c;
  padding: 10px 20px;
  margin: 50px 0;
  position: relative;
}

#error404 > div p,
#error404 > div li {
  font-family: "fetropolis ", sans-serif;
  font-size: 12px;
  color: #dddddd;
  margin: 10px 0;
}

#error404 > div .social-media-icons {
  display: flex;
  margin: 10px 100px 40px;
}

#error404 > div .social-media-img {
  margin: 0 5px;
  height: 30px;
  border-radius: 3px;
  overflow: hidden;
}

#error404 > div .social-media-img img {
  transition: transform 0.4s cubic-bezier(0.55, 0.085, 0, 0.99);
}

#error404 > div .social-media-img img:hover {
  transform: translateY(-30px);
}

#error404 > div > span {
  font-family: "fetropolis", sans-serif;
  font-size: 10px;
  color: #808080;
  position: absolute;
  right: 20px;
  bottom: 20px;
}