#album-videos {
  margin-top: 77px;
}

#album-videos .all-videos {
  margin-top: 50px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 35px;
}