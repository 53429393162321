#legal {
  width: 100%;
  padding: 0 calc(50% - 599px);
  flex-grow: 1;
  background-color: #f5f8fd;
}

#legal > h1 {
  font-weight: normal;
  font-size: 48px;
  line-height: 35.5px;
  margin: 50px 0 40px 0;
  text-align: center;
  font-family: "orbitron bold", sans-serif;
}

.legal-nav {
  width: 100%;
  height: 35px;
  border-bottom: 1px solid #808080;
  display: flex;
  position: relative;
}

.legal-nav button {
  padding: 0 20px;
  height: 35px;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #808080;
  cursor: pointer;
  transition: 0.2s;
  font-family: 'agency', sans-serif;
  font-size: 20px;
  display: block;
  filter: brightness(1) !important;
}

.legal-nav .abt-active {
  border-bottom: 1px solid #437cb2;
  color: #437cb2;
}

.legal-nav button:hover {
  color: #437cb2;
}

#legal .outer {
  overflow: hidden;
  height: auto;
  transition: height 0.6s, opacity 0.5s;
}

#legal .inner {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden auto;
}

.text-pp,
.text-tos {
  display: none;
}

.lgl-main {
  padding: 0 0 43px 0;
  display: flex;
}

.abt-text {
  width: auto;
  position: relative;
  top: -2px;
}

.abt-text h2 {
  color: #1c1c1c;
  font-size: 14px;
  font-family: "orbitron bold", sans-serif;
  font-weight: normal;
  margin-top: 50px;
}

.lgl-main .abt-text p:not(:first-child),
.lgl-main .abt-text ul:not(:first-child) {
  font-size: 14px;
  font-family: 'nimbus light', sans-serif;
  color: #808080;
  position: relative;
  margin-top: 20px;
}

.abt-text a {
  color: #1c1c1c;
  transition: 0.2s;
}

.abt-text a:hover {
  color: #437cb2;
}
