.media-nav {
  width: 100%;
  height: 35px;
  margin-top: 50px;
  border-bottom: 1px solid #808080;
  display: flex;
  position: relative;
}

.media-nav button {
  padding: 0 20px;
  height: 35px;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #808080;
  color: #dddddd;
  cursor: pointer;
  transition: 0.5s;
  font-family: "agency", sans-serif;
  font-size: 20px;
  display: block;
  filter: brightness(1) !important;
}

.media-nav a:last-child > button {
  position: absolute;
  right: 0px;
}

.media-nav .media-active {
  border-bottom: 1px solid #437cb2;
  color: #437cb2;
}

.media-nav button:hover {
  color: #437cb2;
}
