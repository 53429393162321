#live-bar {
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  top: 0;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: #b24343;
  font-family: "fetropolis", sans-serif;
  font-size: 14px;
  color: #dddddd;
  overflow: clip;
}

#live-bar::before {
  content: "";
  display: block;
  position: absolute;
  height: auto;
  aspect-ratio: 1;
  animation: 3s .5s infinite live;
  z-index: -1;
  border-radius: 50%;
  background: white;
}

@keyframes live {
  from {
    width: 0%;
    opacity: 0.2;
  }
  to {
    width: 100%;
    opacity: 0;
  }
}