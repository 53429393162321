.faq-section:not(:first-child) {
  margin-top: 50px;
}

.faq-section h4 {
  font-size: 20px;
  color: #1c1c1c;
  font-weight: normal;
  font-family: "agency", sans-serif;
  margin-bottom: 20px;
}