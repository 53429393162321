.slider {
  width: 5990px;
  height: 468px;
  transition: 0.4s;
  position: relative;
  left: 0;
}

.slider .overlay {
  top: 0;
  left: 0;
  width: 5990px;
  height: 468px;
  display: flex;
  position: absolute;
}

.slider .overlay div {
  width: 1198px;
  height: 468px;
  background-color: #0004;
  transition: 0.5s;
  position: relative;
  display: flex;
  align-items: center;
}

.slider .overlay div:hover {
  background-color: #0000;
}

.slider .overlay div#n0 {
  background-color: #0000;
}

.slider .overlay div#n0 button {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  cursor: pointer !important;
}

.slider .slider-arrow {
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.4s;
}

.slider .prev-img {
  right: 50px;
}

.slider .next-img {
  left: 50px;
}

#sliderButtonsB {
  background-color: #808080;
  position: absolute;
  width: 1198px;
  bottom: 0;
  left: calc(50% - 599px);
  transition: 0.5s;
  height: 8px;
  display: flex;
}

.SBB {
  flex: 1;
  height: 8px;
  z-index: 50;
}

.sbb {
  width: 100%;
  height: 18px;
  cursor: pointer;
}

.sbb span {
  display: block;
  width: 100%;
  height: 8px;
  background: transparent;
  transition: 0.2s;
}

.sbb:hover span {
  background: #dddddd;
}

.sbol {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #437cb2;
  height: 8px;
  transition: 0.8s;
  cursor: pointer;
}
