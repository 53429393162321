#albums {
  margin-top: 50px;
}

#albums .content {
  position: relative;
  width: 1208px;
  left: -5px;
  margin-top: -57px;
}

#albums .content .subtitle {
  text-align: center;
  font-family: "orbitron";
  font-size: 14px;
  line-height: 10px;
  color: #dddddd;
  margin: 119px 0 45px;
}

#albums .content .all-albums {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 35px;
}