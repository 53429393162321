#latest-audio {
  position: relative;
  padding-top: 2px;
}

#latest-audio .loading-track {
  width: 100%;
  height: 40px;
  position: relative;
}

#latest-audio .loading-track::after {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#latest-audio .loading-track td {
  position: relative;
}

#latest-audio .loading-track .loading-data {
  width: 60%;
  height: 12px;
  border-radius: 4px;
  background-color: #363636;
  animation: load 2s infinite;
  position: absolute;
  top: 14px;
  left: 20%;
  z-index: 2;
}

#latest-audio .loading-track td:nth-child(1) .loading-data {
  width: 18px;
  left: 16px;
}

#latest-audio .loading-track td:nth-child(2) .loading-data {
  left: initial;
}

#latest-audio .loading-track td:nth-child(7) .loading-data {
  left: initial;
  right: 16px;
}
