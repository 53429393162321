#about {
  width: 100%;
  padding: 0 calc(50% - 599px);
  background-color: #f5f8fd;
}

#about > h1 {
  font-weight: normal;
  font-size: 48px;
  line-height: 35.5px;
  margin: 50px 0 40px 0;
  text-align: center;
  font-family: "orbitron bold", sans-serif;
}

#about .abt-nav {
  width: 100%;
  height: 35px;
  border-bottom: 1px solid #808080;
  display: flex;
  position: relative;
}

#about .abt-nav a {
  display: content;
}

#about .abt-nav button {
  padding: 0 20px;
  height: 35px;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #808080;
  cursor: pointer;
  transition: 0.2s;
  font-family: "agency", sans-serif;
  font-size: 20px;
  display: block;
  filter: brightness(1) !important;
}

#about .abt-nav > a:last-child > button {
  position: absolute;
  right: 0px;
}

#about .abt-nav .abt-active {
  border-bottom: 1px solid #437cb2;
  color: #437cb2;
}

#about .abt-nav button:hover {
  color: #437cb2;
}

#about .text-news:not(.text-about),
#about .text-resume:not(.text-about),
#about .text-faq:not(.text-about),
#about .text-test:not(.text-about) {
  display: none;
}

#about .text-resume {
  width: 1140px;
  flex-wrap: wrap;
}

#about .text-resume > div {
  overflow: hidden;
  width: 245px;
  height: 160px;
  margin: 32px 40px 10px 0;
  position: relative;
  top: 5px;
}

#about .text-resume > div > div {
  position: absolute;
  bottom: -30px;
  display: flex;
  width: 100%;
  transition: 0.75s;
}

#about .text-resume > div > div > button {
  margin: 0;
  box-sizing: border-box;
  height: 30px;
  width: 50%;
  transition: 0.5s;
  cursor: pointer;
  background-color: #437cb2bb;
  border: 1px solid #437cb2;
  border-radius: 0;
}

#about .text-resume > div:hover > div {
  bottom: 0;
}

#about .text-resume h4 {
  font-family: "orbitron bold", sans-serif;
  font-size: 20px;
  position: absolute;
  color: #1c1c1c;
  font-weight: normal;
  text-align: center;
  width: 1100px;
  top: -2px;
}

#about .text-resume img {
  transition: 0.75s;
  cursor: pointer;
}

#about .text-resume > div > div > button:hover {
  background-color: #437cb2;
}

#about .abt-main {
  padding: 50px 0 43px 0;
  display: flex;
}

#about .outer {
  transition: height 0.45s, opacity 0.375s;
  height: auto;
}

#about .inner {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

#about .abt-images {
  width: 200px;
  height: 240px;
  margin-right: 80px;
}

#about .abt-images > img {
  margin-bottom: 16px;
}

#about .abt-images img {
  transition: 0.75s;
  cursor: pointer;
  margin: 0 !important;
}

#about .abt-images img:hover,
.text-resume > div:hover img {
  opacity: 0.5;
}

#about .abt-images > div {
  width: 200px;
  height: 20px;
  display: flex;
  justify-content: space-between;
}

#about #galleryview {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  opacity: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 1);
  z-index: -1;
  transition: 0.5s;
  display: none;
}

#about #galleryviewcont {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
  display: flex;
  padding: 0;
  margin: 0;
  transition-timing-function: cubic-bezier(0.22, 0.91, 0.28, 0.98);
}

#about .galleryviewbox {
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  transition: 1s;
}

#about .galleryviewimg {
  display: inline-block;
  width: 1198px;
  height: 674px;
  cursor: zoom-in;
  vertical-align: middle;
}

#about .galleryviewvid {
  display: inline-block;
  width: 1024px;
  height: 578px;
  vertical-align: middle;
}

#about #leftarrow {
  position: absolute;
  top: 50%;
  left: 50px;
  width: 34px;
  height: 66px;
  margin-top: -33px;
  z-index: 100000;
  cursor: pointer;
}

#about #rightarrow {
  position: absolute;
  top: 50%;
  right: 50px;
  width: 32px;
  height: 64px;
  margin-top: -33px;
  z-index: 100000;
  cursor: pointer;
}

#about #galleryexit {
  width: 24px;
  height: 24px;
  z-index: 100000;
  cursor: pointer;
  position: absolute;
  top: 50px;
  right: 50px;
}

#about #galleryexit svg #leftarrow svg #rightarrow svg {
  width: 100%;
  height: 100%;
  transition: 0.5s;
}

#about #galleryexit path #leftarrow path #rightarrow path {
  transition: 0.5s;
}

#about #galleryexit:hover path,
#leftarrow:hover path,
#rightarrow:hover path {
  stroke: #437cb2;
}

#about .abt-text {
  width: 100%;
  color: #808080;
  font-size: 14px;
  font-family: "nimbus light", sans-serif;
  position: relative;
  top: -2px;
}

#about .abt-text p:not(:first-child) {
  margin-top: 13px;
}

#about .abt-text > div h5 {
  color: #1c1c1c;
  font-size: 20px;
  font-family: "agency", sans-serif;
  font-weight: normal;
  margin-bottom: 3px;
}

#about .abt-text > div:first-child h5 {
  margin-top: -1px;
}

#about .abt-text > div h6 {
  color: #1c1c1c;
  font-size: 12px;
  font-family: "nimbus light", sans-serif;
  font-weight: normal;
}

#about .abt-text > div ul {
  margin-inline-start: -5px;
}

/*#about .abt-text > div li {
  color: #1c1c1c;
  margin: 13px 0 23px 0;
}*/

#about .abt-text > div:last-child li {
  margin: 13px 0 0 0;
}

#about .abt-text > div a {
  font-size: 14px;
  font-family: "nimbus light", sans-serif;
  color: #1c1c1c;
  transition: 0.2s;
  position: relative;
  top: 1px;
  left: -1px;
}

#about .abt-text > div a:hover {
  color: #437cb2;
}

#about .abt-text a {
  color: #1c1c1c;
  transition: 0.2s;
}

#about .abt-text a:hover {
  color: #437cb2;
}

#about .abt-text .a {
  color: #808080;
  margin-left: 5px;
  font-family: "nimbus", sans-serif;
}

#about .abt-text .q {
  color: #1c1c1c;
  display: inline-block;
  font-family: "nimbus bold", sans-serif;
}

#about .abt-text .q:first-child {
  margin-top: -1px;
}

#about .abt-text .q:nth-child(4) {
  margin-top: 2px;
}

#about .text-faq {
  width: 100%;
}
