@font-face{
  font-family: 'agency';
  src: url('./assets/fonts/AgencyFB Light Regular.otf');
  font-display: swap;
}

@font-face {
  font-family: "fetropolis";
  src: url("./assets/fonts/Fetropolis Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "fetropolis bold";
  src: url("./assets/fonts/Fetropolis Bold.otf");
  font-display: swap;
}

/* REMOVE - start */
@font-face{
  font-family: 'nimbus';
  src: url('./assets/fonts/Nimbus-Sans-D-OT.ttf');
  font-display: swap;
}

@font-face{
  font-family: 'nimbus light';
  src: url('./assets/fonts/Nimbus-Sans-D-OT-Light.ttf');
  font-display: swap;
}

@font-face{
  font-family: 'nimbus bold';
  src: url('./assets/fonts/Nimbus-Sans-D-OT-Bold.ttf');
  font-display: swap;
}

@font-face{
  font-family: 'open sans';
  src: url('./assets/fonts/OpenSans-VariableFont_wdth,wght.ttf');
  font-display: swap;
}
/* REMOVE - end */

@font-face{
  font-family: "orbitron";
  src: url('./assets/fonts/Orbitron.ttf');
  font-display: swap;
}

@font-face{
  font-family: "orbitron bold";
  src: url('./assets/fonts/Orbitron-Bold.ttf');
  font-display: swap;
}

@keyframes load {
  from {
    background: #363636;
  }

  50% {
    background: #404040;
  }

  to {
    background: #363636;
  }
}

@keyframes in-to-out {
  0% {
    opacity: 1;
    top: 0;
  }
  100% {
    opacity: 0;
    top: -5px;
  }
}

@keyframes out-to-in {
  0% {
    opacity: 0;
    top: 5px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes waivy {
  0%,
  56%,
  100% {
    transform: translateY(0)
  }
  28% {
    transform: translateY(-2px)
  }
}

* {
  margin: 0;
  user-select: none;
  text-decoration: none;
  box-sizing: border-box;
}

html {
  overflow: -moz-scrollbars-vertical;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  position: relative;
  color: #1c1c1c;
}

button,
a {
  color: inherit;
}

body {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f8fd;
  position: relative;
  overflow-x: hidden;
}

/* Webkit */
*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;/* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* button titles */
*[data-title] {
  position: relative;
  overflow: visible;
}

*[data-title]::after {
  content: attr(data-title);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, calc(100% + 10px));
  width: 60px;
  height: 21px;
  background-color: #dddddd;
  color: #1c1c1c;
  font-size: 10px;
  font-family: "fetropolis bold";
  text-transform: uppercase;
  border-radius: 3px;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
  z-index: 1000;
}

*[data-title]:hover::after {
  opacity: 1;
}