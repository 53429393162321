#contact {
  width: 100%;
  padding: 0 calc(50% - 599px);
  height: 579px;
  background: #f5f8fd;
  overflow: hidden;
  position: relative;
}

/*#contact::before {
  content: "";
  background-image: url(../../assets/images/contact/background.svg);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}*/

#contact > h1 {
  font-weight: normal;
  font-size: 48px;
  line-height: 35.5px;
  margin: 50px 0 46px 0;
  text-align: center;
  font-family: "orbitron bold", sans-serif;
}

.contact-main {
  width: 100%;
  height: auto;
  display: flex;
}

.contact-desc {
  width: 314px;
}

.contact-desc h5,
.contact-desc h6 {
  font-family: 'agency', sans-serif;
  font-size: 20px;
  font-weight: normal;
}

.contact-desc .box {
  margin-top: 43px;
  display: flex;
}

.contact-desc .icon {
  width: 24px;
  height: 24px;
  position: relative;
  top: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.contact-desc p,
.contact-desc a {
  font-family: 'nimbus light';
  font-size: 12px;
  color: #808080;
  margin-top: 13px;
  transition: 0.2s;
}

#contact a {
  color: #1c1c1c;
  transition: 0.2s;
}

.contact-desc > .box:nth-child(2) p:not(:nth-child(2)) {
  margin-top: -2px !important;
}

#contact a:hover {
  color: #437cb2;
}

.contact-form {
  width: auto;
  flex-grow: 1;
}

.contact-wrapper {
  height: 649px;
  width: 100%;
}

.contentbg {
  width: 100%;
}

.content {
  width: auto;
  margin: 0 auto;
  color: white;
  font-family: "nimbus light", Arial, Helvetica, sans-serif;
  font-size: 14px;
  text-align: justify;
  color: #808080;
}

.content > div > span:first-child {
  position: relative;
  top: -1px;
  font-size: 12px;
}

.inputbox:not(#reason, #sel) {
  width: calc(50% - 5px);
  height: 30px;
  margin: 5px 0;
  background-color: white;
  color: #1c1c1c;
  border-style: solid;
  border-width: 2px;
  border-color: #e9ecef;
  border-radius: 1px;
  font-size: 12px;
  padding: 3px 3px 3px 10px;
  font-family: Arial, Helvetica, sans-serif;
  outline: none;
  border-radius: 4px;
}

#reason,
#sel {
  width: calc(50% - 5px) !important;
  height: 30px;
  margin: 5px 0;
  background-color: white;
  color: #1c1c1c;
  border-style: solid;
  border-width: 2px;
  border-color: #e9ecef;
  border-radius: 1px;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  outline: none;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
}

#reason:has(input:focus),
#sel:has(input:focus) {
  border-color: #437cb2;
}

/*#reason:has(input:invalid),
#sel:has(input:invalid) {
  border-color: #ca0000;
}*/

#reason:has(input[disabled]),
#sel:has(input[disabled]) {
  opacity: 0.5;
}

#reason > div:not([id]),
#sel > div:not([id]) {
  width: 100%;
  min-height: 0;
  height: 32px;
  padding: 0 8px;
}

#reason > div[id],
#sel > div[id] {
  margin: 5px 0 0 -2px;
  background: white;
  border: 2px solid #e9ecef;
  width: calc(100% + 4px);
}

#reason > div[id] > div > div,
#sel > div[id] > div > div {
  padding-left: 10px;
  height: 24px;
  display: flex;
  align-items: center;
}

#reason > div[id] > div > div:hover,
#sel > div[id] > div > div:hover {
  background-color: #437cb2;
  color: white;
}

#fullname,
#reason {
  margin-right: 10px !important;
}

.active-result {
  font-family: Arial, Helvetica, sans-serif !important;
}

#contactform {
  margin-top: 9px;
  margin-bottom: 5px;
  position: relative;
  overflow: hidden;
}

.contact-button {
  box-sizing: border-box;
  width: 64px;
  height: 22px;
  cursor: pointer;
  overflow: hidden;
  border: none;
  background-color: #437cb2 !important;
  position: absolute;
  top: 339px;
  right: 4px;
  border-radius: 4px;
  font-size: 12px;
}

#sendingcontact {
  position: absolute;
  width: 100px;
  text-align: center;
  left: calc(50% - 50px);
  font-size: 12px;
  font-family: open sans, sans-serif;
  font-weight: bold;
  bottom: 165px;
  color: white;
  visibility: hidden;
  transition: 0.3s;
}

.inputbox:focus {
  border-color: #437cb2 !important;
}

#contact label {
  position: absolute;
  z-index: 1;
  font-size: 12px;
  color: #437cb2;
  top: 13px;
  display: none;
}

.result-contact {
  width: 100%;
  height: 388px;
  display: flex;
  position: absolute;
  top: 365px;
}

.result-contact-inner {
  background-color: white;
  height: 376px;
  width: 0;
  transition: 1s cubic-bezier(0.77, 0, 0.175, 1);
  text-align: center;
  font-family: open sans, sans-serif;
  font-weight: bold;
  font-size: 12px;
  padding-top: 160px;
  color: #262626;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: -10px;
}

.h-over {
  width: 100%;
  height: 348px;
  position: absolute;
  top: 376px;
  background-color: #f5f8fd;
  z-index: 101;
}

.submit {
  box-sizing: border-box;
  width: 64px;
  height: 22px;
  position: absolute;
  top: 339px;
  right: 4px;
  float: right;
  padding-top: 7px;
  color: white;
  font-family: open sans, sans-serif;
  font-size: 11px;
  cursor: pointer;
  overflow: hidden;
  flex-direction: column;
  transition: 0.2s;
}

.submit-inner1,
.submit-inner2 {
  display: block;
  height: 29px;
  text-align: center;
  transition: 0.3s;
  line-height: 0.8;
}

.submit:hover .submit-inner1 {
  margin-top: -29px;
}

.check {
  transform: scale(1.2);
  margin-right: 12px;
  stroke-dasharray: 18px;
  stroke-dashoffset: 18px;
  animation: stroke-in 0.6s 0.4s cubic-bezier(0.77, 0, 0.175, 1) forwards;
}

.x {
  transform: scale(1.2);
  margin-right: 12px;
  stroke-dasharray: 42px;
  stroke-dashoffset: 42px;
  animation: stroke-in 0.6s 0.4s cubic-bezier(0.77, 0, 0.175, 1) forwards;
}

@keyframes stroke-in {
  to {
	stroke-dashoffset: 0;
  }
}

#errormessage {
  text-align: left;
  font-family: open sans, sans-serif;
  font-size: 11px;
  color: #ca0000;
  z-index: 102;
  position: absolute;
  left: 0;
  bottom: 17px;
}
