#connect {
  width: 100%;
  padding: 77px calc(50% - 599px) 28px;
  background-color: #282828;
}

.connect-content-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.connect-left,
.connect-right {
  width: 50%;
  text-align: center;
  width: auto;
}

.connect-left h2,
.connect-right h2 {
  font-weight: bold;
  padding-bottom: 3px;
  font-family: "agency", sans-serif;
  font-size: 20px;
  color: #dddddd;
}

.connect-left p,
.connect-right p {
  color: #dddddd;
  font-family: "fetropolis", sans-serif;
  font-weight: bold;
  font-size: 12px;
  padding-bottom: 16px;
  padding-top: 10px;
}

.email-input {
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  width: 437px;
  margin: 0 calc(50% - 218.5px) 4px;
  position: relative;
  border-radius: 4px;
}

.newsletter-outer {
  width: 437px;
  height: 30px;
  overflow: hidden;
}

#newsletter-email {
  width: 437px;
  box-sizing: border-box;
  height: 30px;
  outline: none;
  border: 2px solid #e9ecef;
  padding-left: 10px;
  z-index: 100;
  padding-right: 66px;
}

.newsletter-button {
  box-sizing: border-box !important;
  width: 64px;
  height: 22px;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  border: none;
  background-color: #437cb2 !important;
  position: relative;
  top: -26px;
  right: 4px;
  float: right;
  margin-right: 0px;
  transform-origin: 64.173px 50%;
}

#sendingnewsletter {
  position: absolute;
  text-align: center;
  width: 100px;
  left: calc(50% - 50px);
  top: 8px;
  font-size: 12px;
  font-family: open sans, sans-serif;
  font-weight: bold;
  color: white;
  visibility: hidden;
}

.result {
  width: 437px;
  height: 28px;
  z-index: 100;
  display: flex;
}

.result-inner {
  margin-top: -4px;
  background-color: #ffffff;
  height: 30px;
  width: 0;
  transition: 1s cubic-bezier(0.77, 0, 0.175, 1);
  text-align: center;
  font-family: open sans, sans-serif;
  font-weight: bold;
  font-size: 12px;
  padding-top: 9px;
  color: #ffffff;
  z-index: 100;
}

.submit-newsletter {
  box-sizing: border-box;
  position: absolute;
  width: 64px;
  height: 22px;
  bottom: 4px;
  float: right;
  padding-top: 7px;
  right: 4px;
  color: white;
  font-family: open sans, sans-serif;
  font-size: 11px;
  cursor: pointer;
  overflow: hidden;
  flex-direction: column;
  transition: 0.2s;
}

.submit-inner-newsletter1,
.submit-inner-newsletter2 {
  display: block;
  height: 22px;
  text-align: center;
  transition: 0.3s;
  line-height: 0.8;
}

.submit-newsletter:hover .submit-inner-newsletter1 {
  margin-top: -22px;
}

.check {
  transform: scale(1.2);
  margin-right: 12px;
  stroke-dasharray: 18px;
  stroke-dashoffset: 18px;
  animation: stroke-in 0.6s 0.4s cubic-bezier(0.77, 0, 0.175, 1) forwards;
}

.x {
  transform: scale(1.2);
  position: relative;
  top: 1px;
  stroke-dasharray: 42px;
  stroke-dashoffset: 42px;
  animation: stroke-in 0.6s 0.4s cubic-bezier(0.77, 0, 0.175, 1) forwards;
}

@keyframes stroke-in {
  to {
	stroke-dashoffset: 0;
  }
}

.inputboxcheck:invalid {
  border-color: #ca0000 !important;
}

.inputbox::placeholder,
#newsletter-email::placeholder {
  color: #88909b;
}

.inputbox:focus,
#newsletter-email:focus {
  border-color: #437cb2;
}

.inputbox:focus::placeholder,
#newsletter-email:focus::placeholder {
  color: transparent;
}

#errormessagecontact {
  width: 437px;
  text-align: left;
  font-family: open sans, sans-serif;
  font-size: 11px;
  display: inline-block;
  color: #ca0000;
}

#connect .social-media-icons {
  display: flex;
  justify-content: center;
}

#connect .social-media-img {
  margin: 0 5px;
  height: 30px;
  border-radius: 3px;
  overflow: hidden;
}

#connect .social-media-img img {
  transition: transform 0.4s cubic-bezier(0.55, 0.085, 0, 0.99);
}

#connect .social-media-img img:hover {
  transform: translateY(-30px);
}
