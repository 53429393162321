.audio-collection {
  width: 100%;
  color: #808080;
  font-family: "fetropolis", sans-serif;
  font-size: 11px;
  border-collapse: collapse;
  text-align: center;
}

.audio-collection th {
  color: #dddddd;
  font-weight: bold;
}

.audio-collection tbody {
  border-width: 1px 0;
  border-style: solid;
  border-color: #808080;
}

.audio-collection tbody::before,
.audio-collection tbody::after {
  content: "#";
  display: table-row;
  line-height: 10px;
  color: transparent;
}

.audio-collection tbody td {
  transition: 0.2s;
}

.audio-collection tbody td:nth-child(1) {
  width: 50px;
}

.audio-collection tbody td:nth-child(2) {
  width: 420px;
}

.audio-collection tbody td:nth-child(3) {
  width: 140px;
}

.audio-collection tbody td:nth-child(4) {
  width: 137px;
}

.audio-collection tbody td:nth-child(5) {
  width: 116px;
}

.audio-collection tbody td:nth-child(6) {
  width: 229px;
}

.audio-collection tbody td:nth-child(7) {
  width: 106px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.audio-collection tbody tr:hover td {
  background: #303030;
}

.audio-collection tbody tr[data-selected="true"] td:not(:nth-child(6)) {
  background: #303030;
  color: #437cb2;
}

.audio-collection tbody tr[data-selected="true"] td:nth-child(6) {
  background: #303030;
  color: #808080;
}

.audio_track-number {
  width: 50px;
}

.audio-collection td:first-child {
  position: relative;
}

.audio-collection td:first-child * {
  transition: 0.2s;
}

.audio_track-play-pause {
  display: inline-flex;
  padding: 6px;
  background: transparent;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 13px);
  left: calc(50% - 11px);
  border: 0;
  color: #808080;
  opacity: 0;
}

.audio-collection tbody tr:hover td:first-child span {
  opacity: 0;
}

.audio-collection tbody tr:hover td:first-child button {
  opacity: 1;
}

.audio_track-play-pause:hover {
  /*background: hsl(0deg 0% 100% / 10%);*/
  color: #dddddd;
}

.audio_track-play-pause[data-playing="false"] svg path:first-child {
  d: path("M5 3.18L0 0V14L5 10.82Z");
  transition: 0.4s d;
}

.audio_track-play-pause[data-playing="false"] svg path:last-child {
  d: path("M11 7L5 3.18V10.82L11 7Z");
  transition: 0.4s d;
}

.audio_track-play-pause[data-playing="true"] svg path:first-child {
  d: path("M4 0L0 0V14L4 14Z");
  transition: 0.4s d;
}

.audio_track-play-pause[data-playing="true"] svg path:last-child {
  d: path("M11 0L7 0V14L11 14Z");
  transition: 0.4s d;
}

.audio-collection th:nth-child(2),
.audio-collection td:nth-child(2) {
  text-align: left;
  padding-left: 30px;
  height: 40px;
}

.audio-collection th:nth-child(2) {
  padding-left: 31px;
}

/*.audio-collection tbody tr:nth-child(even) {
  background: #2b2b2b;
}*/

.audio-collection th:last-child,
.audio-collection td:last-child {
  padding-right: 16px;
  text-align: right;
}

.audio-collection a {
  color: inherit;
}

button.track-title,
button.track-album {
  margin: -4px -5px -3px -6px;
  padding: 4px 5px 3px 6px;
  background: none;
  border: 0;
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  background: transparent;
  transition: color 0.2s;
}

button.track-title:hover,
button.track-album:hover {
  /*background: hsl(0deg 0% 100% / 10%);*/
  color: #437cb2;
}

.audio_track-positive,
.audio_track-negative {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.audio_track-positive span {
  position: absolute;
  left: 27px;
  top: 0;
}

.audio_track-negative span {
  position: absolute;
  right: 25px;
  top: 0;
}

.audio_track-positive {
  margin-right: 70px;
}

.audio_track-thumbs-up,
.audio_track-thumbs-down {
  padding: 4px;
  margin: -4px;
  display: inline-flex;
  border: 0;
  background: transparent;
  color: #808080;
  cursor: pointer;
  transition: 0.2s;
}

.audio_track-thumbs-up:hover,
.audio_track-thumbs-down:hover {
  color: #dddddd;
  /*background: hsl(0deg 0% 100% / 10%);*/
}

.audio_track-description,
.audio_track-share,
.audio_track-options {
  padding: 4px;
  display: inline-flex;
  border: 0;
  background: transparent;
  color: #808080;
  cursor: pointer;
  transition: 0.2s;
}

.audio_track-share {
  margin: 0 12px;
}

.audio_track-description:hover,
.audio_track-share:hover,
.audio_track-options:hover {
  color: #dddddd;
  /*background: hsl(0deg 0% 100% / 10%);*/
}

.audio-collection tbody tr {
  position: relative;
}

.audio-collection tr .menu {
  width: 160px;
  height: auto;
  padding: 10px 0;
  background-color: #363636;
  border-radius: 4px;
  position: absolute;
  z-index: 2;
  top: 12px;
  right: 28px;
  display: none;
}

.audio-collection tr .menu[data-open="true"] {
  display: block;
}

.audio-collection tr .menu button {
  width: 100%;
  height: 27px;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "proxima", sans-serif;
  font-size: 10px;
  font-weight: bold;
  color: #dddddd;
  cursor: pointer;
  text-align: left;
}

.audio-collection tr .menu button:hover {
  background-color: #3f3f3f;
}

.audio-collection tr > .description {
  width: 100% !important;
  background-color: transparent !important;
  padding: 0 !important;
}

.audio-collection tr > .description > .outer {
  height: 0;
  transition: 0.4s height;
  overflow: clip;
}

.audio-collection tr > .description > .outer .container {
  padding: 10px 0;
}

.audio-collection tr > .description > .outer .container .content {
  width: 100%;
  height: auto;
  padding: 20px;
  border-radius: 4px;
  background-color: #1c1c1c;
}

.audio-collection tr > .description > .outer .container .content > .top {
  font-size: 10px;
  color: #808080;
  font-family: "fetropolis", sans-serif;
  line-height: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.audio-collection tr > .description > .outer .container .content > p {
  color: #dddddd;
  height: auto;
  font-size: 11px;
  font-family: "fetropolis", sans-serif;
}

.audio-collection tr > .description > .outer .container .content > .social-media-icons {
  display: flex;
  width: fit-content;
  margin: 47px auto;
}

.audio-collection tr > .description > .outer .container .content > .social-media-icons .social-media-img {
  margin: 0 5px;
  height: 30px;
  border-radius: 3px;
  overflow: hidden;
}

.audio-collection tr > .description > .outer .container .content > .social-media-icons .social-media-img img {
  transition: transform 0.4s cubic-bezier(0.55, 0.085, 0, 0.99);
}

.audio-collection tr > .description > .outer .container .content > .social-media-icons .social-media-img img:hover {
  transform: translateY(-30px);
}

.audio-collection tr > .description > .outer .container .content > .copyright {
  font-size: 10px;
  color: #808080;
  font-family: "fetropolis", sans-serif;
  line-height: 8px;
  display: flex;
  align-items: center;
  opacity: 1 !important;
}

.audio-collection tr > .description > .outer .container .content > .copyright a {
  color: #808080;
}

.audio-collection tr > .description > .outer .container .content > .copyright a:hover {
  color: #437cb2;
}