#copy-message {
  display: flex;
  width: 130px;
  height: 21px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: #dddddd;
  color: #1c1c1c;
  font-family: "fetropolis bold", sans-serif;
  font-size: 10px;
  position: fixed;
  margin: 0 auto 100px;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  transition: 0.4s opacity;
  z-index: 100000;
}