.testimonials {
  margin: 2px 0 -8px;
  width: 1198px;
  height: auto;
  position: relative;
}

.testimonials > .testimonials-arrow {
  position: absolute;
  top: 50%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  background: none;
  border: 0;
}

.testimonials > .testimonials-arrow.left {
  transform: translate(-100%, -50%);
  left: -50px;
}

.testimonials > .testimonials-arrow.right {
  transform: translate(100%, -50%);
  right: -50px;
}

.testimonials > .testimonials-arrow > svg {
  transition: 0.2s;
}

.testimonials > .testimonials-arrow:hover > svg {
  stroke: #437cb2;
}

.testimonials .splide__pagination {
  display: flex !important;
  position: absolute !important;
  top: -40px !important;
  right: 12px !important;
  bottom: auto !important;
  left: auto !important;
  margin: 0 !important;
  padding: 0 !important;
  height: 4px !important;
  margin: 0 !important;
}

.testimonials .splide__pagination > li {
  width: 14px !important;
  height: 4px !important;
  margin: 0 0 0 1px !important;
  font-size: 0 !important;
  line-height: 0 !important;
}

.testimonials .splide__pagination__page {
  box-sizing: border-box !important;
  background-color: #808080 !important;
  outline: none !important;
  border-radius: 0 !important;
  width: 14px !important;
  height: 4px !important;
  transform: none !important;
  overflow: hidden !important;
  opacity: 1 !important;
  transition: 0.25s !important;
  margin: 0 !important;
  position: static !important;
}

.testimonials .splide__pagination__page.is-active {
  background-color: #437cb2 !important;
}

.testimonials .splide__pagination__page:hover {
  background-color: #dddddd !important;
}
